:global {
  // result overrides
  .ant-result-icon,
  .ant-result-image {
    width: initial;
    height: initial;

    .svg-inline--fa {
      font-size: 72px;
    }

    .faCheckCircle {
      color: var(--green-6);
    }

    .faExclamationTriangle,
    .fa-lock {
      color: var(--gold-6);
    }

    .fa-exclamation-circle,
    .fa-file-exclamation {
      color: var(--blue-6);
    }

    .fa-times-circle,
    .fa-file-times {
      color: var(--red-5);
    }
  }
}
