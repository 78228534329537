.analytics_wrapper {
  .heading {
    font-weight: 400;
    font-size: 18px;
    margin: 0;
    line-height: 28px;
    letter-spacing: 0.3px;
  }

  .flex {
    display: flex;
  }

  .justify_between {
    justify-content: space-between;
  }

  .flex_direction_column {
    flex-direction: column;
  }

  .popover {
    background-color: #f1f5fe;
    border-color: #d0dffc;
    border-radius: 5px;
    min-height: 80vh;
    width: 100%;
    margin-top: 32px;
    padding: 24px;
  }

  .popover_container {
    background-color: #d0dffc;
    display: flex;
    gap: 16px;
    align-items: center;

    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      align-items: center;
      padding: 10px 0;
      flex: 1;
    }
  }

  .popover_tag {
    color: #1f242e;
    background-color: #e0e9fe;
    border-style: none;
    letter-spacing: 0.4px;
    line-height: 14px;
    font-weight: 500;
    padding: 6px 8px;
    font-size: 12px;
  }

  .tag_label {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.3px;
    color: #5d6b89;
    text-transform: capitalize;
  }
}

.metrics_popover {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filter_content {
  display: flex;
  gap: 8px;
  flex-direction: column;
  height: 50vh;
  overflow: scroll;
}

.time_filter {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.granularity_filter {
  display: flex;
  gap: 2px;
  flex-direction: column;
}

.time_label {
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #5d6b89;
  text-transform: capitalize;
  margin-bottom: 8px;
}

.granularities {
  display: flex;
  gap: 8px;
}

.filter_radio_group {
  display: flex;
  flex-direction: column;
}

.countries_filter {
  display: flex;
  margin-left: 32px;
  flex-direction: column;
}
