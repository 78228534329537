.wrapper {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.column {
  flex: 1;
  display: block;

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
}
