:global {
  // spin overrides
  .ant-spin {
    color: var(--blue-6);
  }

  .ant-spin.ant-spin-spinning {
    -webkit-animation: fa-spin 700ms infinite linear;
    animation: fa-spin 700ms infinite linear;
  }
}
