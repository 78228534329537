.wrapper {
  padding: 24px;

  .alerts {
    padding: 0 0 15px 0;
  }

  .container {
    display: grid;
    grid-row-gap: 1em;
    grid-template-areas: 'Header' 'Content';
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;

    .header {
      grid-area: Header;
    }

    .content {
      grid-area: Content;
    }
  }
}
