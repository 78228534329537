.modal {
  pointer-events: all;

  & p {
    margin: 0 1px 15px;
    margin-top: 15px;
  }

  & textarea {
    min-height: 100px;
    resize: vertical;
    border: 2px solid var(--blue);
    color: var(--gray-8);
    width: 100%;
  }

  & .alert {
    border: none;
    padding: 0;
    width: auto;
  }

  & .group {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }

  & .modalHeader {
    display: flex;
    justify-content: space-between;

    & h3 {
      margin: auto 0;
      font-weight: 600;
    }
  }

  h5 {
    font-weight: 700;
    margin: 0 0 1em 0;
  }
}
