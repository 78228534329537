
.audit-logs-prop-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 200px;
  overflow-y: auto;

  li {
    cursor: pointer;
    font-size: 16px;
    margin: 8px;
    display: flex;
    width: 40%;
    height: 24px;
    transition: all 0.4s ease-in;
    box-sizing: border-box;
    border: 1px transparent;

    span:first-child {
      margin-right: 4px;
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &.success {
    li:hover {
      border-bottom: 1px solid #5ec4e6;
      color: #5ec4e6;
    }
  }
}

.audit-logs-copy {
  &-input {
    display: block;
    height: 0;
    opacity: 0;
  }

  &-message {
    display: block;
    font-weight: 600;
    height: 20px;
    line-height: 20px;
    padding-left: 6px;
  }
}
