.container {
  padding: 13px 30px 0 30px;

  .content.abData {
    min-height: 21px;
    margin: 0 0 13px;
    justify-content: space-between;
    white-space: nowrap;

    div {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }

  hr {
    margin: 0;
  }

  .cardSubtitle {
    font-size: 12px;
    font-weight: 400;
    margin: 0 10px;
  }

  .content {
    min-width: 300px;
    min-height: 100px;
    background-color: var(--gray-3);
    border-radius: 2px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 25px;
    margin-top: 9px;

    .contentLeft {
      border-right: 1px solid var(--gray-5);
      padding: 0 4rem;
      margin: 3rem 0;
      flex: 0.333;

      :last-child {
        padding-top: 1rem;
      }

      h3 {
        padding-top: 0.5rem;
      }
    }

    .contentRight {
      padding: 0 4rem;
      margin: 3rem 0;
      flex: 0.333;

      :last-child {
        padding-top: 1rem;
      }

      h3 {
        padding-top: 0.5rem;
      }
    }

    .contentCenter {
      border-right: 1px solid var(--gray-5);
      padding: 0 4rem;
      margin: 3rem 0;
      flex: 0.333;

      .contentCenterMain {
        display: flex;
        justify-content: space-between;

        h5 {
          font-weight: bold;
        }

        .sliderContainer {
          display: flex;
          padding-top: 2rem;
          align-items: baseline;

          h5 {
            padding-right: 1rem;
          }
        }

        .contentCenterLeft {
          h3 {
            padding-top: 1rem;
          }
        }

        .contentCenterRightFirst {
          display: flex;
          border-bottom: 1px solid var(--gray-5);
          padding-bottom: 1rem;

          h3 {
            flex: 0.5;
          }

          h5 {
            flex: 0.5;
          }
        }

        .contentCenterRightSecond {
          display: flex;
          padding-top: 1rem;

          h3 {
            flex: 0.5;
          }

          h5 {
            flex: 0.5;
          }
        }
      }
    }
  }

  .separatorLine {
    position: absolute;
    width: 99.8%;
    right: 0.1rem;
    border-color: var(--gray-4);
    border-style: solid;
  }

  .icon {
    min-width: 18px;
    max-width: 18px;
    min-height: 18px;
    max-height: 18px;
    fill: var(--gray-7);
  }
}

.spinner {
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
}

.tableContainer {
  display: inline-block;
  width: 100%;

  .title {
    margin: 1.5em 0 0.7em;
  }
}
