#bulk-update.bulk-update {
  padding: 20px;
  max-width: 1200px;
  margin: 20px auto;
}

.page-column {
  display: block;
  max-width: 186px;
}

.bulk-update-modal {
  .alert {
    font-size: 14px;
    font-weight: 500;
  }

  .body {
    .warningsList {
      margin: 13px 0;
    }

    ol {
      padding-left: 10px;
    }

    li {
      line-height: 1.5;
    }

    .info {
      font-weight: 500;
    }
  }
}
