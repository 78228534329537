.bulk-update-customize-settings {
  .ant-modal-body {
    max-height: 70vh;
    overflow: auto;

    p {
      font-weight: 600;
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 6px;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .ant-checkbox-wrapper,
  .ant-radio-wrapper {
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
  }

  .ant-checkbox-wrapper {
    margin-left: 0;
    margin-top: 6px;
    display: flex;
  }

  .ant-radio-group {
    margin: 4px 0 6px 40px;
  }
}
