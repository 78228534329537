.header {
  // border-top: solid 1px #373e48;
  grid-area: Header;
}

.container {
  display: grid;
  grid-area: Container;
  grid-template-areas: ". Content . Aside .";
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 1fr 6fr 3em 3fr 1fr;
  padding-top: 1em;

  .content {
    grid-area: Content;
    width: 1000px;

    & [class="simple-card"] {
      margin-bottom: 1em;
    }
  }

  .aside {
    grid-area: Aside;

    & > h4 {
      margin-bottom: 1em;
    }

    & > ul {
      list-style: none;

      li {
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.regulationCheckboxes {
  label[for="v2HasGDPR"] {
    margin-bottom: 0.5rem;
  }
}

.twoColumns {
  h5 {
    padding-bottom: 0;
  }

  .column {
    max-width: 50%;
  }

  .singleColumn {
    min-width: 100%;
  }
}

.customizeUi {
  .viewsContainer {
    label {
      overflow: unset;
    }

    img {
      margin-top: 2px;
      padding-left: 23px;
    }

    .RadioGroup {
      white-space: nowrap;
    }

    .Radio {
      border-radius: 2px;
      justify-self: center;
      margin: 0.75em;
      max-width: 270px;
      padding: 20px;

      .imageContainer {
        width: 230px;
      }

      .disableImage {
        opacity: 0.6;
      }
    }

    .RadioSelected {
      border: 1px solid var(--daybreak-5);
    }

    .RadioNoSelected {
      border: 1px solid var(--gray-5);
    }
  }
}

.disagreeButton {
  h5 {
    color: var(--gray-8);
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0;
  }

  span {
    font-size: 12px;
    font-weight: 400;
  }
}

.cardsWrapper {
  .card {
    margin-bottom: 1em;
  }

  h6 {
    color: var(--gray-10);
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .purpouseIds,
  .customLinks {
    h5 {
      font-size: 12.5px;
      font-weight: 700;
      letter-spacing: 0.5px;
      margin-right: 5px;
    }

    span {
      font-size: 12px;
      font-weight: 400;
    }
  }

  [for="uxSoftOptIns"] {
    margin: 1.5rem 0 1.5rem;
  }

  [class~="ant-card"] {
    margin-bottom: 1em;

    [class="simple-card__content"] {
      padding: 1.5em 3em;

      [class~="form-control"] {
        margin: 0.6em 0 2em;

        [class$="__label"] {
          &::after {
            top: -5px;
          }
        }

        &.noReject {
          margin-bottom: 0.5em;

          & + p {
            // margin-bottom: 1em;
          }
        }
      }
    }
  }

  .ccpaFields {
    align-items: baseline;
  }

  .ccpaLinkLabel {
    display: block;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .preview {
    border: 1px solid #d3d4d6;
  }

  .bodyCopies {
    background-color: var(--gray-2);
    margin-right: 12px;
    padding: 1em;
    text-align: justify;
    margin-bottom: 15px;
  }

  .btnPlusToggle {
    background: none;
    border: none;

    & span {
      color: var(--blue);
      margin-right: 10px;

      &:first-child {
        border-radius: 3px;
        display: inline-block;
        text-align: center;
      }

      &:last-child {
        background: none;
        font-size: 14px;
        font-weight: 600;
        margin-right: 0;
      }
    }
  }

  [class="ant-card-head-wrapper"] {
    .cardTitle {
      font-weight: 600;

      span {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  [class~="ant-form-item-label"] > label {
    font-size: 12px;
    font-weight: 700;
    color: var(--gray-9);
  }

  [class~="ant-checkbox-wrapper"],
  [class~="ant-radio-group"] label {
    font-size: 13px;
    font-weight: 400;
    text-transform: none;
  }
}

.tooltip {
  display: inline;
  margin-left: 10px;
}

.tooltipContainer {
  .label {
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    white-space: pre-wrap;
    font-size: 0.75em;
    color: var(--gray-8);
    width: auto;
  }

  .tooltip {
    display: inline;
    margin-left: 10px;
  }
}

.consentOrPay {
  .layoutContainer {
    .Radio {
      border: 1px solid var(--gray-5);
      display: flex;
      align-items: flex-end;
      border-radius: 4px;
      justify-self: center;
      margin: 0.75em;
      max-width: 270px;
      padding: 8px;

      p {
        font-size: 13px;
        font-weight: 400;
      }

      span {
        margin-bottom: 5px;
      }
    }

    img {
      margin-top: 2px;
    }

    .RadioGroup {
      white-space: nowrap;
      display: flex;
    }

    .RadioSelected {
      border: 1px solid var(--daybreak-5);
    }

    .RadioNoSelected {
      border: 1px solid var(--gray-5);
    }
  }
}
