.childrenInfo {
  padding: 15px 0 15px 4%;

  p {
    padding: 0;
    margin: 0;
  }
}

.hidden {
  margin-left: 0;
  visibility: hidden;
}
