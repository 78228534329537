[class="modal-container open"] {
  overflow: hidden; }

[class="modal__portal"] {
  z-index: 16000010 !important; }

.modalPreview {
  background: transparent;
  border: none;
  box-shadow: none;
  width: 100%;
  height: 100%;
  pointer-events: all; }
  .modalPreview [class="modal__header modal__header--only-actions"] {
    border: none;
    height: 10vh;
    background: var(--gray-1); }
  .modalPreview [class="modal__content"] {
    padding: 0; }
  .modalPreview .iframeContainer {
    height: 90vh;
    width: 100%;
    position: relative; }
    .modalPreview .iframeContainer .errorMessage {
      position: absolute;
      top: 10%;
      font-size: 4em;
      text-align: center;
      left: 50%;
      transform: translate(-50%, 0);
      color: var(--gray-1); }
    .modalPreview .iframeContainer [name="__cmpPreview"] {
      border: none; }

.previewHeaderContainer {
  display: flex;
  justify-content: space-between;
  width: 100%; }
