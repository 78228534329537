.toolbar {
  background-color: white;
  border: 1px solid var(--gray-4);
  border-left: none;
  border-right: none;
  height: 47px;
  padding: 0 25px;
  width: 100%;
}

.periodText {
  font-weight: bold;
  margin-bottom: 14px;
  text-align: right;
}
