.wrapper-inline {
  .inline-fields {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    & > div,
    & > label {
      flex: 1 0 49%;
      margin-right: 1em;
      max-width: 49%;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.more_options {
  background-color: #b3c7ee;
  border: 1px solid #f8faff;
  padding: 0;
}
