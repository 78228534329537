.mainHeader {
  background-color: var(--gray-1);
  height: 100px;
  padding-top: 10px;
}

.linkStyles {
  display: flex;
  flex-direction: column;
  margin-left: 60px;
}

.rightContainer {
  display: flex;
  justify-content: space-between;
}

.spinner {
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
}

.toolbar {
  display: flex;
  padding: 0 30px;
}
