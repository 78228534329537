#bulk-update.bulk-update {
  .bulk-update-filter {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px;

    &__column {
      padding: 12px;
    }

    .ant-input,
    .ant-select {
      width: 190px;
      height: 32px;
    }

    .ant-btn {
      width: 190px;
      height: 32px;
    }
  }

  // qc-fieldset ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ----
  .qc-fieldset {
    display: flex;
    margin: 12px 4px;

    &-required {
      padding: 0 2px;
      color: red;
      width: 10px;
    }

    &-label {
      display: block;
      flex: 1;
      margin-right: 12px;

      &--no-content {
        flex: 2;
      }
    }

    @media screen and (max-width: 1080px) {
      display: block;
      text-align: left;
      font-size: 12px;

      &-label {
        display: inline-block;
        width: 80%;
      }
    }
  }
}
