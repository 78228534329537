@mixin radio_style($borderColor) {
  border: 1px solid $borderColor;
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  justify-self: center;
  margin: 0.75em;
  max-width: 270px;
  padding: 20px;
}

.radios {
  & [class="form_group__radio"] {
    @include radio_style(var(--gray-4));
  }

  & [class*="form_group__radio--checked"] {
    @include radio_style(var(--blue));
  }

  & [class*="form-group__radio--disabled"] {
    @include radio_style(var(--gray-4));
  }
}
