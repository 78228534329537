.wrapper {
  margin-bottom: 1.5em;
  display: flex;
  flex-direction: column;
}

div.toolbar {
  border-bottom: none;
  order: 2;
  background: var(--gray-2);
  margin: 0;
  padding: 7px 10px;

  div {
    margin-bottom: 0;

    button {
      border: none;
      background: none;
      opacity: 0.5;

      &[class*='isActive'] {
        opacity: 1;
      }
    }
  }
}

.editor {
  order: 1;
  height: 390px;
}
