.versionBanner {
  .download {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    padding: 8px;
    background-color: #f5f5f5;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 500;
    color: var(--blue-6);
    cursor: pointer;
    transition: all 0.4s ease-in;
    box-sizing: border-box;

    span:first-child {
      margin-right: 4px;
    }

    &:hover {
      background-color: var(--blue-6);
      color: #fff;
    }
  }

  .modalTitle {
    font-size: 18px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0;

    p {
      font-size: 13px;
    }

    .okBtn {
      border-radius: 20px;
    }
  }

  .deadline {
    color: rgba(218, 59, 59, 1);
    background-color: rgba(255, 219, 219, 1);
    padding: 8px;
    border-radius: 8px;
  }

  .details {
    .title {
      font-weight: 500;
      font-size: 15px;
    }

    ol {
      margin-left: 10px;

      li {
        margin-bottom: 5px;
      }
    }
  }
}
