.recommended {
  ul {
    margin: 1rem 0;
    margin-bottom: 2.4rem;

    li {
      margin-bottom: 1rem;

      ul {
        padding-left: 2rem;
      }
    }
  }
}
