.wrapper {
  align-items: center;

  .logo {
    height: 40px;
  }

  .backContainer {
    display: flex;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .title {
    & h2 {
      font-weight: initial;
      margin-bottom: 0;
      display: inline-block;
      margin-right: 8px;
    }
  }

  .column {
    display: flex;
  }

  .left {
    align-items: center;
  }

  .right {
    align-items: flex-end;
  }

  :global {
    .ant-page-header-heading-sub-title {
      align-self: flex-end;
      line-height: 1;
    }
  }
}
