.wrapper {
  .btn {
    background: none;
    border: none;

    & span {
      color: var(--blue);
      margin-right: 10px;

      &:first-child {
        border-radius: 3px;
        display: inline-block;
        text-align: center;
      }

      &:last-child {
        background: none;
        font-size: 14px;
        font-weight: 600;
        margin-right: 0;
      }
    }
  }

  .input {
    height: 130px;
  }
}
