.consentScope {
  h2 {
    font-size: 18px;
    font-weight: 600;
  }

  .alert {
    font-size: 12px;
    font-weight: 400;
    text-transform: none;
    display: flex;
    align-items: flex-start;

    svg {
      margin-top: 4px;
    }
  }

  .EditButton {
    text-transform: uppercase;
    font-weight: 600;
    color: var(--blue-6);
  }

  .radioItem {
    margin-bottom: 12px;
  }

  .optionsLabel {
    font-size: 12px;
    line-height: 1.5;
    font-weight: 600;
    text-transform: none;
  }

  .subTitle {
    font-weight: 400;
    text-transform: none;
    line-height: 1.5;
  }

  [class~='ant-form-item-label'] > label {
    width: 100%;
  }
}
