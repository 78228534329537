.typography_text {
  color: var(--gray-1);
}

.tooltip_with_paragraphs {
  p {
    color: var(--gray-1);
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
