.announcement_wrapper {
  padding: 20px;
  margin: 10px 25px;
  border: 1px solid #2f72f4;
  color: #3b3b3b;
  border-radius: 5px;
  background-color: #fcfdff;
}

.top {
  display: flex;
  gap: 10px;
}

.message {
  color: #3b3b3b;
  margin-top: 5px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.link {
  color: #1e50b3;
  background-color: transparent;
  outline: none;
  border: none;
  text-decoration: underline;
  margin-top: 5px;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  &:hover {
    cursor: pointer;
    color: #163d8a;
  }
}

.success_link {
  color: #476826;
  background-color: transparent;
  outline: none;
  border: none;
  text-decoration: underline;
  margin-top: 5px;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }
}
