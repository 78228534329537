.heading {
    color: #0b4dcc;
    margin-bottom: 30px;
    text-align: center;
    font-family: Poppins,serif;
    font-size: 1.71428571rem;
    font-weight: 500;
    width: 100%;
    padding-top: 25px;
}


.formContainer {
    width: 430px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    
    :global(.ant-spin) {
      color: white !important;
    }
}



.formContainer input {
    border: 0.5px solid #e2e9f8;
    border-radius: 3px;
    color: #000;
    display: block;
    line-height: 28px;
    padding: 5px 10px;
    width: calc(100% - 20px);
    margin-bottom: 10px;
    font-family: Poppins,serif;
    font-size: 14px;
    font-weight: 300;
}

.primaryButton {
    background: #0b4dcc;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 20px auto 0;
    min-width: 182px;
    padding: 8px 38px;
    border-radius: 25px;
    display: block;
    line-height: 35px;
    font-family: Poppins,serif;
    font-size: 13px;
    font-weight: 500;
    background: -webkit-linear-gradient(322deg,#538fe9,#1f67f3);
    background: -o-linear-gradient(322deg,#538fe9,#1f67f3);
    background: linear-gradient(128deg,#538fe9,#1f67f3);
    border-color: linear-gradient(128deg,#538fe9,#1f67f3);
    -webkit-box-shadow: 0 10px 20px 0 rgba(158,190,255,.3);
    box-shadow: 0 10px 20px 0 rgba(158,190,255,.3);
    color: #fff;
    border: 1px solid #e2e9f8;
    cursor: pointer;
}

.tertiaryButton {
    background: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 20px auto 0;
    min-width: 182px;
    padding: 8px 38px;
    border-radius: 25px;
    display: block;
    line-height: 2.5;
    font-family: Poppins,serif;
    font-size: 13px;
    font-weight: 500;
    border: 1px solid #e2e9f8;
    cursor: pointer;
}

@keyframes shake {
    0% {
      margin-left: 0rem;
    }
    25% {
      margin-left: 0.5rem;
    }
    75% {
      margin-left: -0.5rem;
    }
    100% {
      margin-left: 0rem;
    }
}
  
.error {
    color: red;
    padding: 0px 20px;
    animation: shake 0.2s ease-in-out 0s 2;
    max-width: 380px;
    line-break: auto;
    display: inline-block;
}