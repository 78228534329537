.table {
  background: var(--gray-1);
  margin-bottom: 1em;
  width: 100%;

  * {
    padding: auto 0;
  }

  span {
    border: none;
  }

  &.isSites {
    div:last-of-type {
      flex-grow: 0;
    }
  }

  [class='ant-table-tbody'] [class='ant-table-cell'],
  [class='ant-table-tbody'] [class='ant-table-cell ant-table-cell-row-hover'] {
    font-size: 1.4rem;
  }

  [class='ant-table-thead'] > tr > th {
    border-top: 1px solid var(--gray-4);
    font-weight: 600;
  }
}

.pagination {
  justify-content: center;
}
