.table {
  thead {
    tr {
      th {
        font-weight: 600;
      }
    }
  }
}

.loaderWrapper {
  height: 100px;
  position: relative;
}
