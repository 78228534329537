.text-action {
  color: var(--blue);
  line-height: 1;
  margin-bottom: 0;
  padding-top: 4px;
}

.text-lighter {
  color: var(--gray-8);
}

.h4-text {
  font-weight: 800;
  margin-bottom: 0;
}
