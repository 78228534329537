.customizeHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tooltip {
  display: inline;
  margin-left: 8px;
}

.purposeList {
  margin-bottom: 15px;
}

.consentsRadios {
  [class="form-group__radio-group"] {
    width: 100%;
    display: flex;
  }
}

.optionsContainer {
  display: flex;
  justify-content: space-between;
}

.titleHeader {
  margin-top: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.tooltipIconStyles {
  display: inline-block;
  background-color: gray;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  text-align: center;
  color: white;
  border: none;
  font-size: 13px;
  line-height: 16px;
  margin-left: 10px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;

  .titlePurpose {
    display: flex;
    align-items: baseline;

    svg[data-icon="ban"] {
      color: var(--red-7);
      margin-left: 3rem;
    }
  }

  .purposesList {
    margin-bottom: 1rem;
  }

  & > button {
    background: none;
    border: none;
    margin-bottom: 1rem;
    align-items: center;
    color: var(--gray-8);
    font-size: 11px;
    letter-spacing: 1px;

    svg {
      margin-right: 10px;
      transition: transform 0.2s ease;
    }
  }
}

.rotateIcon {
  svg[data-prefix="fas"] {
    transform: rotate(90deg);
  }
}

.restrictionsCheckBox {
  padding-left: 2rem;
}

.active {
  div.hiddenSection {
    display: block;
    max-height: 160px;
    opacity: 1;
    margin-bottom: 2rem;
  }
}

.checkBoxContainer {
  .hiddenSection {
    display: none;
    max-height: 0;
    opacity: 0;
    transition: all 0.4s ease;

    [class~='ant-checkbox-wrapper'] {
      margin-left: 0;
      margin-bottom: 1rem;
      font-size: 13px;
      font-weight: 400;
      text-transform: none;
      display: flex;
    }
  }
}

.modalSize {
  pointer-events: all;

  [class='modal__content'] {
    max-height: 77vh;
    min-height: 40vh;
    overflow-y: auto;
  }

  & footer > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.footerButtons {
  display: flex;
  justify-content: space-between;
}

.usePurposes {
  display: flex;
  justify-content: space-between;
}
