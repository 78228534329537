#details-container.details-container {
  max-width: 800px;
  margin: 20px auto;

  h2 {
    font-weight: 500;
  }

  label {
    font-size: 1.2rem;
  }

  .field-error {
    font-size: 14px;
    margin-top: 4px;
    color: var(--red-6);
  }

  .field-on-error {
    border-color: var(--red-6);
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-checkbox + span,
  .ant-radio + * {
    font-weight: normal;
    letter-spacing: normal;
    text-transform: none;
    font-size: 14px;
  }

  .align-items-center {
    align-items: center;
    display: flex;
  }

  .consent-scope-details {
    margin-top: 8px;
  }

  .customize-purpose-screen-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .alert {
    font-size: 12px;
    font-weight: 400;
    text-transform: none;
  }
}
