.modalForm {
  [class~='ant-form-item-label'] > label {
    font-size: 12px;
    font-weight: 600;
  }

  .radioItem {
    [class~='ant-radio-group'] > label {
      font-weight: 400;
      font-size: 12px;
      text-transform: none;
      margin-bottom: 5px;
    }
  }
}

.enabledItem {
  white-space: pre-wrap;
}

.EditButton {
  text-transform: uppercase;
  font-weight: 500;
  padding: 0;
}
