.modalConent {
  font-weight: 400;

  span {
    font-size: 18px;
  }

  a,
  .reconfirmText {
    font-size: 14px;
  }
}

.modalButtons {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  width: 460px;

  & span {
    font-size: 14px;
  }
}

.mspaSettings {
  label {
    line-height: 1.3;
  }

  .subTitle {
    font-weight: 400;
    text-transform: none;
    line-height: 1.5;
  }

  li {
    font-size: 12px;
    line-height: 1.5;
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
  }

  .alert {
    font-size: 12px;
    text-transform: none;
    font-weight: 400;
    display: flex;
    align-items: flex-start;

    svg {
      margin-top: 4px;
    }
  }

  .radioItem {
    margin-bottom: 12px;
  }

  .checkBoxItem {
    margin-bottom: 0;

    .checkBoxLabel {
      font-size: 12px;
      font-weight: 700;
    }
  }

  .formItemLabel {
    line-height: 1.5;
  }
}
