@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'Navbar' 'Content';
  font-size: 16px;
  width: 100%;

  .navBar {
    grid-area: Navbar;
  }

  .content {
    grid-area: Content;
  }
}

:root {
  // colors
  // grays
  --gray-1: #fff;
  --gray-2: #fafafa;
  --gray-3: #f5f5f5;
  --gray-4: #f0f0f0;
  --gray-5: #d9d9d9;
  --gray-6: #bfbfbf;
  --gray-7: #8c8c8c;
  --gray-8: #595959;
  --gray-9: #434343;
  --gray-10: #262626;
  --gray-11: #1f1f1f;
  --gray-12: #141414;
  --gray-13: #000;
  // reds
  --red-5: #ff4d4f;
  --red-6: #f5222d;
  --red-7: #cf1322;
  // blues
  --blue: #2a68c2;
  --blue-1: #f0f8ff;
  --blue-2: #d3e5f5;
  --blue-3: #a2c5e8;
  --blue-4: #76a5db;
  --blue-5: #4e86cf;
  --blue-6: #2a68c2;
  --blue-7: #1a4a9c;
  --blue-8: #0e3075;
  --blue-9: #061c4f;
  --blue-10: #020d29;
  // misc
  --daybreak-5: #40a9ff;
  --green-6: #52c41a;
  --gold-6: #faad14;
}

:global {
  // base typography
  * {
    font-family: 'Poppins', 'Helvetica Neue', Arial, Verdana, sans-serif;
  }

  html,
  body {
    font-size: 62.5%;
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  .label,
  .p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  li,
  ol,
  p,
  table,
  td,
  th,
  tr,
  ul {
    font-family: 'Poppins', 'Helvetica Neue', Arial, Verdana, sans-serif;
    margin: 0;
    padding: 0;
    color: var(--gray-10);
    font-weight: 400;
    line-height: 1.6rem;
    font-size: 1.3rem;
    -webkit-font-smoothing: antialiased;
  }

  h1,
  .h1 {
    font-size: 3.6rem;
    line-height: 4rem;
  }

  h2,
  .h2 {
    line-height: 2.9rem;
    font-size: 2.4rem;
    font-weight: 800;
  }

  h3,
  .h3 {
    line-height: 2.2rem;
    font-size: 1.8rem;
    font-weight: 600;
  }

  h4,
  .h4 {
    line-height: 1.6rem;
    font-size: 1.4rem;
    font-weight: 800;
  }

  h5,
  .h5 {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 400;
    color: var(--gray-8);
  }

  h6,
  .h6 {
    line-height: 1.6rem;
    font-size: 1.3rem;
    font-weight: 600;
    color: rgba(33, 41, 52, 0.4);
  }

  p,
  .p {
    line-height: 2.1rem;
    font-size: 1.4rem;
    color: var(--gray-10);
  }

  a,
  a.ant-typography,
  .ant-typography a {
    color: var(--blue-6);
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
  }

  a:hover,
  a.ant-typography:hover,
  .ant-typography a:hover,
  a:active,
  a.ant-typography:active,
  .ant-typography a:active,
  a:visited,
  a.ant-typography:visited,
  .ant-typography a:visited {
    text-decoration: none;
    color: var(--blue-5);
  }

  data {
    font-family: 'Liberation Mono', 'Poppins', 'Helvetica Neue', Arial, Verdana, sans-serif;
    font-weight: normal;
    line-height: 1.15;
    font-size: 1.4rem;
    color: var(--gray-10);
  }

  label,
  .label {
    display: block;
    margin: 0 0 4px;
    line-height: 1;
    margin-bottom: 8px;
    font-size: 1.2rem;
    font-weight: 900;
    letter-spacing: 1px;
    color: var(--gray-8);
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  code,
  kbd,
  pre,
  samp,
  pre > code {
    font-family: monospace;
    font-size: 1.3rem;
  }

  textarea,
  .textarea {
    padding: 10px;
  }

  div {
    -webkit-font-smoothing: antialiased;
  }

  // button overrides
  .ant-btn:not(.ant-btn-round):not(.ant-btn-circle) {
    border-radius: 3px;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: var(--blue-5);
    border-color: var(--blue-5);
  }

  .ant-btn-primary {
    border-color: var(--blue-6);
    background: var(--blue-6);
  }

  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    border-color: var(--blue-5);
    background: var(--blue-5);
    color: var(--gray-1);
  }

  a.ant-btn-primary:visited {
    color: var(--gray-1);
  }

  .ant-btn-link {
    color: var(--blue-6);
  }

  .ant-btn-link:hover,
  .ant-btn-link:focus {
    color: var(--blue-5);
    border-color: var(--gray-1);
  }

  .ant-btn-text:hover,
  .ant-btn-text:focus {
    color: rgba(0, 0, 0, 0.85);
    background: rgba(0, 0, 0, 0.018);
    border-color: transparent;
  }

  // todo: why is this missing?
  .ant-btn > .svg-inline--fa + span,
  .ant-btn > span + .svg-inline--fa {
    margin-left: 8px;
  }

  // card overrides
  .ant-card-head {
    font-weight: 600;
    font-size: 18px;
  }

  .ant-card-extra {
    color: var(--blue-6);
    font-weight: 600;
    font-size: 18px;
    opacity: 0.5;
  }

  // checkbox overrides
  .ant-checkbox-inner {
    border-radius: 3px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--blue-6);
    border-color: var(--blue-6);
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: var(--gray-3);
    border-color: var(--gray-5) !important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--blue-6);
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--blue-6);
  }

  // collapse icon size override
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    display: block;
    font-size: 20px;
    text-align: center;
    width: 10px;
  }

  // date picker color overrides
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: var(--blue-6);
  }

  .ant-picker-today-btn {
    color: var(--blue-6);
  }

  .ant-picker-today-btn:hover {
    color: var(--blue-5);
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: var(--blue-6);
  }

  // date picker & input overrides
  .ant-picker:focus,
  .ant-picker-focused,
  .ant-input:focus,
  .ant-input-focused,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: var(--blue-6);
    box-shadow: 0 0 0 2px rgba(42, 104, 194, 0.2);
  }

  .ant-picker:hover,
  .ant-input:hover,
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: var(--blue-6);
  }

  .ant-input-prefix .svg-inline--fa {
    margin-right: 4px;
  }

  // form overrides
  .ant-form-item-label > label .ant-form-item-tooltip {
    cursor: pointer;
  }

  // info tooltip icon
  .info-icon-container {
    vertical-align: middle;
  }

  // menu overrides
  .ant-menu-item .svg-inline--fa {
    min-width: 14px;
    font-size: 14px;
  }

  .ant-menu-item-selected,
  .ant-menu-light .ant-menu-item:hover {
    color: var(--blue-6);
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: var(--blue-1);
  }

  //radio overrides
  .ant-radio-checked .ant-radio-inner {
    border-color: var(--blue-6);
  }

  .ant-radio-inner::after {
    background-color: var(--blue-6);
  }

  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: var(--blue-6);
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px var(--blue-1);
  }

  .ant-radio.ant-radio-disabled .ant-radio-inner {
    border-color: var(--gray-5) !important;
  }

  // select overrides
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--blue-6);
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--blue-1);
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--blue-6);
    box-shadow: 0 0 0 2px rgba(42, 104, 194, 0.2);
  }

  // tab & table color overrides
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-table-cell a:not(.ant-btn) {
    color: var(--blue-6);
  }

  .ant-tabs-ink-bar {
    background: var(--blue-6);
  }

  .ant-tabs-tab:hover,
  .ant-table-cell a:not(.ant-btn):hover {
    color: var(--blue-5);
  }

  .ant-table-custom-row-expand-icon {
    display: inline-block;
    background: transparent;
    border: none;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    height: 17px;
    line-height: 11px;
    margin-right: 8px;
    outline: none;
    padding: 1px 5px;
    transition: transform 0.3s ease-out;
    width: 17px;
  }

  .ant-table-custom-row-expand-icon:hover {
    color: var(--blue-8);
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: var(--blue-1);
  }

  // table pagination overrides
  .ant-pagination-item a {
    font-weight: 400;
    line-height: 32px;
  }

  .ant-pagination-mini .ant-pagination-item a {
    line-height: 22px;
  }

  .ant-pagination-item:hover,
  .ant-pagination-item-active {
    border-color: var(--blue-6);
  }

  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-item:hover a,
  .ant-pagination-item-active a {
    color: var(--blue-6);
  }

  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: var(--blue-6);
    border-color: var(--blue-6);
  }

  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link {
    border-color: var(--gray-5);
    color: rgba(0, 0, 0, 0.25);
  }

  // tooltip arrow size overrides
  // padding changes only for top placement
  // less variable alternative -- @popover-arrow-width
  .ant-tooltip-placement-top,
  .ant-tooltip-placement-topLeft,
  .ant-tooltip-placement-topRight {
    padding-bottom: 8px;
  }

  .ant-tooltip-arrow {
    height: 13px;
    width: 13px;
  }

  // tooltip link colors
  .ant-tooltip-content a,
  .ant-tooltip-content a:visited {
    color: var(--blue-4);
  }

  .ant-tooltip-content a:hover {
    color: var(--blue-3);
  }

  // increase dot badge size
  .ant-badge .ant-badge-status-dot {
    height: 10px;
    width: 10px;
  }

  .ant-modal-root label {
    line-height: 16px;
    letter-spacing: 0.3px;
    font-size: 12px;
    font-weight: 500;
    color: #1f242e;
  }

  details summary {
    // color: #5D6B89;
    color: #5d6b89;
    font-weight: 500;
    font-size: 12px;
    text-transform: capitalize;
    letter-spacing: 0.3px;
    font-style: normal;
    list-style: none; /* Remove default list style */
    cursor: pointer;
  }

  details summary::before {
    content: ">";
    font-weight: 500;
    font-size: 12px;
    text-transform: capitalize;
    letter-spacing: 0.3px;
    font-style: normal;
    list-style: none; /* Remove default list style */
    cursor: pointer;
    padding-right: 8px;
  }

  .ant-tag-checkable {
    background-color: #d0dffc;
    color: #1f242e;
    border-radius: 4px;
  }

  .ant-tag-checkable:hover {
    color: #fff;
    background-color: #0c55e4;
    border: 1px solid #0c55e4;
  }

  .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: #fff;
    background-color: #0c55e4;
    border: 1px solid #0c55e4;
  }

  .ant-tag-checkable-checked {
    color: #fff;
    background-color: #0c55e4;
    border: 1px solid #0c55e4;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
    border: none;
  }

  .flex {
    display: flex;
  }

  .flex-column {
    flex-direction: column;
  }
}
