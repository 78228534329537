.container {
  margin-bottom: 0;
}

.regulationCheckboxes {
  label[for] {
    margin-bottom: 0.5rem;
  }

  .container {
    margin-bottom: 10px;
  }
}

.warningAlert {
  margin-bottom: 14px;
  font-size: 12px;
  display: flex;
  align-items: flex-start;

  svg {
    margin-top: 4px;
  }
}

.checkbox {
  margin-bottom: 0 !important;
}

.formItemToolTip {
  display: inline-block;
}

.formTooltip {
  display: flex;
  margin-bottom: 0;
}

.themeAlert {
  border: solid 1px var(--daybreak-5);
  padding: 1em;

  p {
    color: var(--daybreak-5);
  }
}

.ccpa {
  font-size: 1.2rem;

  h5 {
    letter-spacing: 1px;
    margin-bottom: 7px;
    line-height: 1;

    & + label[for] {
      margin-bottom: 0.5rem;
    }
  }

  .checkboxLspa {
    overflow: visible;
    position: relative;
    display: inline-flex !important;

    .questionIcon {
      color: var(--gray-10);
      margin-left: 0.5rem;
    }

    p {
      position: absolute;
      background: var(--gray-8);
      color: var(--gray-1);
      padding: 10px 12px;
      top: 25px;
      left: -25px;
      width: 345px;
      z-index: 1000000;
      display: none;
      transition: all 0.25s ease;

      a {
        color: var(--gray-1);
        text-decoration: underline;
        font-weight: 400;
      }
    }

    &::before {
      display: block;
      content: "";
      position: absolute;
      background: var(--gray-8);
      transform: rotate(45deg);
      right: 1px;
      top: 20px;
      width: 10px;
      height: 10px;
      z-index: 90;
      opacity: 0;
      transition: all 0.25s ease;
    }

    &:hover {
      p,
      &::before {
        display: block;
      }
    }
  }
}

.cardsWrapper {
  margin: 10px 0 10px 0;
  padding-right: 12px;

  .consentCard {
    margin-bottom: 1em;

    [class~='ant-card-body'] {
      padding: 20px 40px;
    }
  }

  .cardSubtitle {
    font-size: 12px;
    font-weight: 400;
    margin: 0 10px;
  }

  [class~='ant-form-item-label'] > label {
    font-size: 12px;
    font-weight: 700;
  }

  [class='simple-card__content'] {
    padding: 20px 45px;

    & > div {
      margin-right: 0;
    }
  }

  [class~='ant-checkbox-wrapper'],
  [class~='ant-radio-group'] label {
    font-size: 13px;
    font-weight: 400;
    text-transform: none;
  }
}

.spaceForm {
  display: flex;
  width: 100%;
}

.twoColumns {
  h5 {
    padding-bottom: 0;
  }

  .column {
    max-width: 50%;
  }

  .singleColumn {
    min-width: 100%;
  }
}

.subdomainToolTip {
  font-style: 'italic';
  font-weight: 600;
}

.addTopMargin {
  margin-top: 15px;
}
