.wrapper {
  h5 {
    &:not(.suggestion) {
      padding-bottom: 16px;
      font-weight: bold;
    }
  }

  .suggestion {
    color: var(--red-6);
  }

  p {
    margin-bottom: 1rem;
  }

  ul {
    margin: 1rem 0;
    padding-left: 20px;

    li {
      margin-bottom: 1rem;
      color: var(--gray-7);

      ul {
        padding-left: 2rem;
      }
    }
  }
}
