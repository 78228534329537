.helpCenterBubble {
  position: fixed;
  bottom: 10px;
  right: 10px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.helpCenterBubbleIcon {
  font-size: 24px;
}
