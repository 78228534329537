.gbcForm {
  .subTitle {
    text-transform: none;
    font-weight: 400;
    line-height: 1.5;
  }

  .title {
    font-weight: 600;
    letter-spacing: 1px;
  }

  .EditButton {
    text-transform: uppercase;
    font-weight: 500;
    padding: 0;
  }

  .formItem {
    margin-bottom: 5px;

    [class~='ant-form-item-row'] {
      display: flex;
      flex-direction: column;
    }

    [class~='ant-form-item-label'] {
      text-align: left;
    }
  }

  [class~='ant-form-item-label'] > label {
    font-size: 12px;
    font-weight: 700;
    width: 100%;
  }

  .alert {
    min-width: 100%;
    margin-bottom: 10px;
  }

  .gbcAlert {
    font-size: 12px;
    font-weight: 500;
    text-transform: none;
  }

  .radioItem {
    margin-left: 25px;
    margin-bottom: 10px;

    [class~='ant-form-item-label'] > label,
    [class~='ant-radio-group'] > label {
      font-weight: 400;
      text-transform: none;
      margin-bottom: 0;
    }
  }

  [class~='ant-checkbox-wrapper'],
  [class~='ant-radio-group'] label {
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    text-transform: none;
  }
}
