:global {
  // alert overrides
  .ant-alert {
    display: flex;
    align-items: center;
  }

  .ant-alert-info {
    background-color: var(--blue-1);
    border: 1px solid var(--blue-3);
  }

  .ant-alert-info .ant-alert-icon {
    color: var(--blue-6);
  }
}
