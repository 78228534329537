.wrapper {
  padding: 24px;
}

.container {
  display: grid;
  grid-row-gap: 1em;
  grid-template-areas: 'Header' 'Content';
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;

  .noResults {
    border: dashed 1px var(--gray-6);
    padding: 4em 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    h2 {
      margin: 24px 0;
      text-align: center;
      font-size: 36px;
      font-weight: 500;
      color: var(--gray-11);
    }

    svg {
      height: 128px;
      width: 128px;
    }
  }

  .header {
    grid-area: Header;
  }

  .toolbar {
    display: grid;
    grid-column-gap: 1em;
    grid-template-areas: 'Headline ActionBtns';
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;

    .headline {
      align-self: center;
      grid-area: Headline;
    }

    .actionBtns {
      align-self: center;
      grid-area: ActionBtns;
      justify-self: flex-end;

      & button {
        margin-right: 1em;

        & svg {
          height: 1em;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .content {
    grid-area: Content;
  }
}
