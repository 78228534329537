.customFormLabel label,
.accordionTitle {
  color: var(--gray-9);
  font-size: 12px;
}

.accordionTitle {
  font-weight: bold;
}

.accordionPanel {
  background-color: white;

  div {
    border: none !important;
  }
}

.purposeSectionList {
  max-height: 160px;
  overflow-y: auto;

  label {
    font-size: 13px;
    font-weight: normal;
    padding: 5px;
    text-transform: none;
  }
}

.consentsRadios {
  display: flex;
  padding-left: 2rem;
}
