.wrapper {
  padding: 24px;
  height: 100%;

  .container {
    display: grid;
    grid-row-gap: 1em;
    grid-template-areas: "propertySelection" "content";
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;

    .propertySelection {
      grid-area: propertySelection;
      display: flex;
      justify-content: space-between;
    }

    .content {
      grid-area: content;
    }

    .dropDown {
      width: 500px;
    }

    .metricValue {
      font-weight: 500;
      font-size: 24px;
      color: rgba(12, 85, 228, 1);
    }

    .title {
      font-size: 18px;
      font-weight: 500;
    }

    .subTitle {
      font-size: 14px;
      font-weight: 500;
    }

    .subDescription {
      font-size: 12px;
      font-weight: 400;
    }

    .comparisonPercentage {
      font-weight: 500;
      font-size: 12px;
    }

    .analyticsViewer {
      width: 100%;

      .actions {
        display: flex;
        width: 100%;

        .filterBtn {
          color: rgba(12, 85, 228, 1);
        }
      }
    }

    .metricsCard {
      padding-top: 25px;
      justify-content: flex-start;
    }

    .metricsCardItem {
      display: flex;
      padding: 15px 35px;
      background-color: rgba(241, 245, 254, 1);
      border-radius: 5px;
      border: 1px solid rgba(208, 223, 252, 1);
    }

    .appliedFilters {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .removeBtn {
      border: none;
    }

    .button {
      color: #0c55e4;
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
    }

    #tagItem {
      color: rgb(31, 36, 46);
      font-size: 11px;

      span {
        color: rgba(118, 133, 163, 1);
        margin-left: 5px;
      }
    }

    .filterLabel {
      font-size: 12px;
      font-weight: 500;
      color: rgba(93, 107, 137, 1);
    }

    .iconContainer {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(215, 226, 250, 1);
      border-radius: 50%;
      vertical-align: middle;
      height: 21px;
      width: 21px;
    }

    .noData {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 30px;
      width: 100%;

      p {
        font-weight: 500;
      }

      .icon {
        @extend .iconContainer;

        background-color: rgba(241, 245, 254, 1);
        height: 100px;
        width: 100px;
      }
    }

    .graphCard {
      background-color: rgba(241, 245, 254, 1);
      border-radius: 4px;
      padding: 14px;

      .header {
        display: flex;
        justify-content: space-between;
        padding: 10px;
      }

      .content {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
      }

      .noData {
        height: 400px;
        display: flex;
        justify-content: center;
      }

      .pieChart {
        tspan {
          stroke-width: 0;
        }
      }
    }

    .tableCard {
      background-color: rgba(241, 245, 254, 1);
      width: inherit;
      border-radius: 4px;
      padding: 14px;

      .header {
        display: flex;
        justify-content: space-between;
        padding: 10px;
      }

      .tableRowLight {
        background-color: rgba(255, 255, 255, 1);
      }

      .tableRowDark {
        background-color: rgba(241, 245, 254, 1);
      }

      td {
        background: transparent;
      }

      .table {
        padding: 0 10px;
      }
    }
  }
}

.filterDrawer {
  .container {
    height: 100%;
    padding: 20px 60px 0 20px;

    .header {
      display: flex;
      justify-content: flex-end;
      padding-top: 60px;
    }

    .content {
      display: flex;
      flex-direction: column;
    }

    .appliedFilters {
      border-bottom: 1px solid #a9b2c5;
      padding-bottom: 15px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .removeBtn {
      border: none;
    }

    .button {
      color: #0c55e4;
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
    }

    .filters {
      flex: 1;
      margin-top: 55px;
    }

    .footerDivider {
      border-top: 1px solid #a9b2c5;
    }

    .actionButtons {
      width: 100%;
      justify-content: flex-end;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 400;
  }

  .subTitle {
    font-size: 14px;
    font-weight: 400;
  }

  #tagItem {
    color: rgb(31, 36, 46);
    font-size: 11px;

    span {
      color: rgba(118, 133, 163, 1);
      margin-left: 5px;
    }
  }

  .filterLabel {
    font-size: 12px;
    font-weight: 500;
    color: rgba(93, 107, 137, 1);
  }

  .checkboxItem {
    display: flex;
    flex-direction: column;
    gap: 5px;

    label {
      font-size: 12px;
      font-weight: 400;
      text-transform: none;
    }
  }
}
