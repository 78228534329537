.table {
  [class~='ant-table-expanded-row-level-1'] [class~='ant-table-cell'] {
    background-color: var(--gray-2);
  }

  [class='ant-table-thead'] > tr > th {
    border-top: 1px solid var(--gray-4);
    font-weight: 600;
  }

  .childInformation {
    padding-left: 4%;

    .description,
    .privacy {
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }
  }
}
