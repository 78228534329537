.bottomFields {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.vendorsDays {
  width: 50%;
}

.warningAlert {
  font-size: 12px;
  display: flex;
  align-items: flex-start;

  svg {
    margin-top: 4px;
  }
}
