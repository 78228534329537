// cop.module.scss
.container {
  width: 100%;
}

.modalContent {
  .imageContainer {
    display: flex;
    gap: 20px;
  }

  .featureSection {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    align-items: center;

    .featureList {
      margin-top: 20px;
      text-align: left;
      font-size: 16px;
      line-height: 1.5;
      list-style-position: outside;

      li {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    gap: 20px;
  }
}

.button {
  width: 100%;
  border-radius: 100px !important;
}

.ul {
  margin-top: 20px;
  text-align: left;
  font-size: 16px;
  line-height: 1.5;
}

.li {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.footer_btns {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  gap: 20px;
}

.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
}

.success_footer_btns {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.success_btn {
  width: 30%;
  border-radius: 100px !important;
}
