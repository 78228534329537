.filterItem {
  display: flex;

  label {
    vertical-align: middle;
  }
}

.table {
  width: 100%;

  .vendorBlocked {
    background: var(--gray-2);

    .textBlocked {
      color: var(--gray-7);
    }
  }

  .checkBoxSizeLabel {
    text-transform: none;
    font-size: 1.3rem;
    font-weight: 500;
  }

  .legalBasis {
    font-size: 1.3rem;
  }

  [class='ant-table-thead'] > tr > th {
    border-top: 1px solid var(--gray-4);
    font-weight: 600;
  }
}
