.customRadio {
  label {
    &:nth-of-type(2) {
      span {
        top: 5px;
      }
    }
  }
}

.purposeSection {
  h5 {
    padding-bottom: 16px;
  }

  p {
    margin-bottom: 1rem;
    font-weight: bold;
  }

  [class~='ant-checkbox-wrapper'],
  [class~='ant-radio-wrapper'] {
    font-size: 13px;
    font-weight: 400;
    text-transform: none;
  }

  [class~='ant-checkbox-wrapper'],
  [class~='ant-checkbox-wrapper'] + [class~='ant-checkbox-wrapper'] {
    margin-left: 0;
  }

  .consentsRadios {
    padding-left: 2rem;
  }
}
