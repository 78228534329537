.wrapper {
  display: grid;
  grid-column-gap: 1em;
  grid-template-areas: 'Header''Container';
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 1fr;

  .header {
    // border-top: solid 1px #373e48;
    grid-area: Header;
  }

  .container {
    display: grid;
    grid-area: Container;
    grid-template-areas: '. Content . Aside .';
    grid-template-rows: repeat(2, auto);
    grid-template-columns: 1fr 8fr 3em 3fr 1fr;
    padding-top: 1em;

    .content {
      grid-area: Content;
      width: 100%;

      & [class="simple-card"] {
        margin-bottom: 1em;
      }
    }

    .aside {
      grid-area: Aside;

      & > h4 {
        margin-bottom: 1em;
      }

      & > ul {
        list-style: none;

        li {
          margin-bottom: 1em;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
