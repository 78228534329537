.checkbox-label {
  color: var(--gray-9);
  font-size: 1.2rem;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 1;
  padding-top: 2px;
}

.preview-image-container {
  border: 1px solid var(--gray-5);
}

.field-error {
  font-size: 14px;
  margin-top: 4px;
  color: var(--red-6);
}

.field-on-error {
  border-color: var(--red-6);
}
