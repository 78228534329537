#bulk-update-status.bulk-update-status {
  padding: 20px;
  max-width: 1200px;
  margin: 20px auto;

  p {
    font-size: 15px;
  }

  span {
    font-weight: bold;
  }
}

.alert--full-page {
  margin-top: 40px;

  svg {
    font-size: 50px;
  }
}
