.element {
  margin-bottom: 0;
  min-width: 20em;
  max-width: 30em;

  & [class$="__suffix"] {
    cursor: pointer;
  }

  &.empty {
    & [class$="__suffix"] {
      cursor: auto;
      opacity: 0;
    }
  }
}
