.wrapper {
  padding: 24px;
  display: grid;
  grid-row-gap: 1em;
  grid-template-areas: 'Alerts' 'Header' 'Content';
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;

  [class="form-control__content"] {
    width: 100%;
  }

  .alerts {
    grid-area: Alerts;
  }

  .header {
    grid-area: Header;

    .headline {
      align-self: center;
      grid-area: Headline;
    }

    .actionBtns {
      align-self: center;
      grid-area: ActionBtns;
      justify-self: flex-end;

      & button {
        margin-right: 1em;

        & svg {
          height: 1em;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .content {
    grid-area: Content;
  }

  .icon {
    font-size: 19px;
    color: var(--gray-13);
    opacity: 0.85;
  }
}

.buttonLink {
  &:visited {
    color: white;
  }
}

.modal {
  padding-top: 0;
}

@media screen and (max-height: 700px) {
  .modal {
    padding-top: 35px;
  }
}
