.qc-card {
  background-color: white;
  border: 1px solid var(--gray-5);
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

  &__header {
    border-bottom: 1px solid var(--gray-5);
    padding: 20px;

    h2 {
      display: inline-block;
      font-size: 20px;
      margin-bottom: 0;
      margin-right: 0.5em;
    }

    span {
      &.small {
        font-size: 12px;
      }

      &.regulation {
        color: var(--blue-6);
        float: right;
        font-size: 18px;
        font-weight: 600;
        opacity: 0.5;
      }
    }
  }

  &__content {
    padding: 20px 40px;
  }
}
