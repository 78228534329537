.vendor-counter {
  margin-top: 1.3em;

  &.--push {
    padding-left: 93px;
  }
}

.disclaimer {
  margin-top: 0.3em;

  p {
    font-size: 0.8em;
  }
}

.row {
  display: flex;

  .column {
    flex: 1 0 auto;

    &.--align-right {
      align-items: center;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.--centered {
  display: flex;
}
